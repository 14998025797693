.container-slider {
  width: 250px;
  height: 120px;
  margin: 5px auto 0;
  position: relative;
  overflow: hidden;
  /* box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); */
}
@media screen and (max-width: 700px) {
  .container-slider {
    margin: 100px 10px 0;
  }
}
.slide {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  transition: opacity ease-in-out 0.4s;
}
.slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.active-anim {
  opacity: 1;
}

.btn-slide {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #f1f1f1;
  border: 1px solid rgba(34, 34, 34, 0.287);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.btn-slide img {
  width: 10px;
  height: 10px;
  pointer-events: none;
}
.prev {
  top: 50%;
  left: 20px;
  transform: translateY(-60%);
}
.next {
  top: 50%;
  right: 20px;
  transform: translateY(-60%);
}

.container-dots {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
}
.dot {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  border: 3px solid #f1f1f1;
  margin: 0 5px;
  background: #f1f1f1;
}
.dot.active {
  background: rgb(32, 32, 32);
}

/* Modal box css */

.paperModal {
  position: relative;
  top: 160px;
  left: 50%;
  transform: translateX(-50%);
  width: 800px;
  height: 535px;
  border-radius: 20px;
  background-color: #fff;
  
}

.confirm-modal {
  position: relative;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  width: 500px;
  height: 110px;
  border-radius: 10px;
  background-color: #fff;
  padding: 12px;
  box-shadow: 0 6px 17px rgb(0 0 0 / 25%) !important;
  
}

/* .confirm-alert{
  display: flex;
  align-items: center;
  justify-content: center;
} */

.confirm-alert .confirm-button {
  font-size: 12px;
  width: 85px;
  height: 30px;
  border: none;
  background-color: #4BD3C5;
  color: white;
  border-radius: 0.4rem;
  margin-right: 10px;
  font-weight: bold;
  cursor: pointer;
  padding: 6px 18px;
  text-align: center;
}

.confirm-alert .cancel-button {
  font-size: 12px;
  padding: 6px 18px;
  text-align: center;
  width: 85px;
  height: 30px;
  border: none;
  background-color: #f07167;
  color: white;
  border-radius: 0.4rem;
  font-weight: bold;
  cursor: pointer;
}

.closeHolder img {
  float: right;
  margin: 20px;
  cursor: pointer;
}

.pdfViewModal {
  width: 90%;
  padding: 10px;
  height: 70%;
  margin: 10% 7% 10% 4%;
  position: absolute;
  overflow: auto;
}

.modal_image_preview {
  /* display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center; */
  position: absolute;
  top: 75px;
  left: 50px;
  width: 700px;
  height: 450px;
  overflow: hidden;
  text-align: center;
}

.modal_image_preview img {
  width: 100% !important;
  max-height: 400px;
  height: 90%;
  object-fit: contain;
  /* background-position: center;
	background-size: contain; */
}

.modal_image_preview button {
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
  color: white;
  background: rgb(240, 113, 103);
  height: 35px;
  width: 100px;
  border: none;
  border-radius: 0.4rem;
  font-size: 14px;
  font-weight: 500;
  padding: 3px 8px;
  margin-top: 6px;
  cursor: pointer;
}
/* .delete-icon{
  padding-right: 4px;
  padding-left: 0px;
} */
