.navbar_container {
    display: flex;
    padding:15px 25px;
   
    
  }

  .navbar_main_left {
    position: relative;
    background: linear-gradient(180deg, #68dec6 2.97%, #69c3cb 100%);
    border-radius: 35px;
    padding-top: 50px;
    width: 70px;
    flex-direction: column;
    display: flex;
    align-items: center;
    min-height: 100vh;	 
  }

  .dropdown {    
    cursor: pointer;
  }
  .navbar_menu_icons{
    height:350px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    margin-top: 4em;
  }

  .navbar_mobile_container{
    background-color: #FFF;
    z-index: 99;
  }

  .navbar_mobile_logo {
    display: none;
  }

  .navbar_mobile_menu {
    display: none;
  }
  
  .navbar_main_right {
    flex: 1;
    padding: 20px 16px 0px 16px; 
  }

  /* Logout Modal css classes */

  .modalBox {
    position: fixed; /* Stay in place */
    z-index: 1111111; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  }
  
  .modal-content {
    background-color: #fefefe;
    margin: 15% auto; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: 45%; /* Could be more or less, depending on screen size */
    border-radius: 20px;
  
    position: relative;
  }
  
  .closeIcn {
    position: absolute;
    right: 21px;
    width: 24px;
    cursor: pointer;
  }

    
  .modal_btn {
    background: #f07167;
    border: none;
    outline: none;
    padding: 12px;
    width: 20%;
    color: white;
    border-radius: 10px;
    margin-top: 24px;
    font-size: 15px;
    cursor: pointer;
  }
  
  /* ============================== */


  @media only screen and (max-width: 769px) {
    .navbar_mobile_menu {
      display: block;
    }
    .navbar_main_left  {
      display: none;
    }
  
    .navbar_main_right {
      width: 100%;
      padding: 0 !important; 
    }

    .navbar_mobile_logo {
      position: fixed;
      background-color: #4ed3c5;
      margin-top: -30px;
      padding: 5px;
      margin-left: -18px;
      width: 65px;
      border-radius: 10px;
      display: inline-flex;
    }
    .navbar_mobile_container {
      margin-top: 0px;
      position: fixed;
      padding: 44px;
      display: list-item;
      width: 100%;
    }
  }