.login-container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
}

.login-main {
	display: flex;
	flex-direction: column !important;
	align-items: flex-start;
	padding-left: 25px;
}

.form_back {
	position: relative;
	background: linear-gradient(90deg, #4bd3c5 2.97%, #4bd3c5 100%);
	border-radius: 15px;
	padding-top: 15px;
	width: 85%;
	min-height: 25vh;
	margin-bottom: 30px;
}

.form_overlay {
	background: #FFF;
	border-radius: 15px;
	box-shadow: 3px 4px 13px lightgrey;
	min-height: 25vh;
}

.login_otp_container {
	width: 65%;
	margin: 4em auto;
	box-shadow: 3px 4px 13px lightgrey;
	text-align: center;
	padding: 1em 3em;
	border-radius: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.login_textfield>.MuiInputBase-root {
	height: 48px;
}

.login_textfield>.MuiInputLabel-root {
	top: -4px;
}

.OTP_textfield {
	text-align: center !important;
	width: 50%;
}

.OTP_textfield>.MuiInputBase-root {
	font-weight: bold;
}

.OTP_textfield ::hover {
	border-bottom: none !important;
}

.OTP_textfield input {
	text-align: center;
}

.resend-btn {
	color: #4bd3c5 !important;
	font-weight: bold !important;
}

@media only screen and (max-width: 600px) {
	.loginBg {
		display: none;
	}
	.OTP_textfield {
		width: 90%;
	}
}

@media only screen and (max-width: 1024px) {
	.loginBg {
		width: 88%;
	}
	.login-main {
		align-items: center;
		padding-left: 0 !important;
	}
}