.header_title {
	font-size: 21px;
	text-align: center;
}

.module_title {
	font-size: 20px;
}

.form_search_field {
	width: 90%;	
}

.srchbtn {
	background: #f07167;
	border: none;
	width: 100px;
	padding: 10px;
	font-size: 15px;
	font-weight: bold;
	color: white;
	border-radius: 9px;
}
.downloadBtn {
	background: #f07167;
	border: none;
	width: 123px;
	padding: 10px;
	font-size: 15px;
	font-weight: bold;
	color: white;
	border-radius: 9px;
	display:flex;
	justify-content: center;
	align-items: center;
}

.srchbtn:focus {
	border: 1px solid #a84f48;
}

.table_head {
	background-color: #4bd3c5 !important;
}

.table_head>.MuiTableRow-root>.MuiTableCell-root {
	padding: 15px 10px !important;
	color: #FFF !important;
	width: 30px !important;
}

.table_head>.MuiTableRow-root>.MuiTableCell-root>.MuiTableSortLabel-root.Mui-active,
.MuiTableSortLabel-root:hover {
	color: #FFF !important;
	font-size: 15px !important;
	font-weight: 600 !important;
}

.table_head>.MuiTableRow-root>.MuiTableCell-root>.MuiTableSortLabel-root.Mui-active .MuiTableSortLabel-icon {
	color: #FFF !important;
}

.table_row>.MuiTableCell-root {
	padding: 10px !important
}

.table_row_size {
	width: 130px;
}

.MuiTableCell-head {
	font-size: 15px !important;
	font-weight: 600 !important;
}

.select_res>.MuiOutlinedInput-input {
	padding: 13.5px 14px !important;
}

.span_text {
	color: #00afb9;
	margin-right: 10px;
}

.preview-img {
	width: 200px;
	height: 120px;
  object-fit: contain;
	cursor: pointer;
}

.preview-img img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	background-position: center;
}

.react-tel-input .form-control {
	color: #000 !important;
	width: 100% !important;
	height: 45px !important;
}
.listBox {	
	max-height: 130px;	
	margin-top: 10px;
}

.listItems_data_withIcon {
	display: flex;
	justify-content: space-between;
}

.listItems_data {
	margin-top: 5px;
	background: rgba(0, 0, 0, 0.03);
	padding: 5px;
	color: #333;
}

.listItems_data>.MuiTypography-root .listItems_data_withIcon>.MuiTypography-root {
	font-size: 14px !important;
}

 .filter_lebel {
	margin-top: 10px;
}

.filter_row {
	display: flex;
	justify-content: center;
}

.flagClass {
	cursor: pointer;
	display:flex;	
	justify-content: space-between;
	align-items: center;
	padding:0px !important;	
	
	}
	
.redFlagText{
	visibility: hidden;
	color:red !important;
	width: 50%;
	text-align: center;
}

.flagBox{
	width: 50%;
}

.redFlagIcon{	
	cursor: pointer;		
}

.redFlagIcon:hover ~ .redFlagText{
	visibility:visible; 	
}

/* Modal box css */

.paperModal {
	position: relative;
	top:160px;
	left: 50%;
    transform: translateX(-50%);
	width:800px;
	height:500px;
	border-radius: 20px;	
	background-color: #fff;
}

.closeHolder img {
	float: right;
	margin: 20px;
	cursor: pointer;
}

.pdfViewModal {
	width: 90%;
	padding: 10px;
	height: 70%;
	margin: 10% 7% 10% 4%;
	position: absolute;
	overflow: auto;
}

.modal_image_preview {
	position: absolute;
	top:75px;
	left:50px;
	width: 700px;
	height: 400px;	
	overflow: hidden;
	text-align: center;
}

.modal_image_preview img {
	width: 100%;
	max-height: 400px;
	height: 100%;
	object-fit: contain;
	/* background-position: center;
	background-size: contain; */
}

.stateLoader{
	display: flex;
	align-items: center;
	justify-content: center;
}

.stateLoader > .MuiCircularProgress-root {
	width: 28px !important;
	height: 28px !important;
}

.removeDose {
	display: flex;
	color: #FFF;
	justify-content: flex-end;
	font-size: 14px;
}

.removebtn {
	background-color:#f07167;
	padding: 3px 8px;
	border-radius: 0.4rem;
	cursor: pointer;
	display: flex;
	align-items: center;
}

@media only screen and (max-width: 900px) {
	.label_row {
		display: none;
	}
	.form_search_field {
		width: 100%;
	}
	.table_row_size>.MuiFormControl-root>.MuiInputBase-root>.MuiSelect-select {
		width: 200px !important;
	}
	.module_title{
		margin-top: 50px !important;
	}
}

@media only screen and (max-width: 1367px) {
	.table_row_size {
		width: 160px;
	}
	.form_search_field {
		font-size: 14px !important;
	}
}

@media only screen and (min-width: 1250px) and (max-width:1367px) {
	.form_search_field {
		width: 90%;
	}
	/* .form_status_field {
		width: 220px !important;
	} */
}