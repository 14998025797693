:root {
	--gray: gray;
	--btnBackground: #4bd3c5;
	--btn1Background: #f07167;
	--fontFamily: "Poppins", sans-serif;
}

*,
*:before,
*:after {
	padding: 0;
	margin: 0;
	outline: 0;
	font-family: var(--fontFamily) !important;
}

html {
	font-size: 16px !important;
	box-sizing: border-box;
	font-family: var(--fontFamily) !important;
}

body {
	font-family: var(--fontFamily) !important;
	-webkit-font-smoothing: antialiased;
	font-display: swap;
}

.pl {
	padding-left: 20px !important;
}

.ml {
	margin-left: 20px !important;
}

.mt {
	margin-top: 15px !important;
}

.mb {
	margin-bottom: 20px !important;
}

.pt {
	padding-top: 15px !important;
}

.pr {
	padding-left: 20px !important;
}

.mr {
	margin-right: 15px !important;
}

.mx {
	margin-right: 15px !important;
	margin-left: 15px !important;
}

.my {
	margin-top: 15px !important;
	margin-bottom: 15px !important;
}

.px {
	padding-left: 20px !important;
	padding-right: 20px !important;
}

.py {
	padding-top: 20px !important;
	padding-bottom: 20px !important;
}

.d-flex {
	display: flex;
	justify-content: center;
	align-items: center;
}

.d-inline-flex {
	display: inline-flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.gray-text {
	color: var(--gray) !important;
}

.main {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	min-height: 100vh; 
	overflow-y: auto;
}

.btn {
	margin: 0 12px;
	width: 140px;
	background-color: var(--btnBackground) !important;
	color: #fff !important;
	outline: none;
	border: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: bold;
	line-height: 1.5;
	font-size: 16px;
	text-align: center;
	text-decoration: none;
	vertical-align: middle;
	cursor: pointer;
	padding: 0.75rem 1rem;
	border-radius: 0.9rem;
	font-family: var(--fontFamily) !important;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn1 {
	margin: 0 12px;
	width: 140px;
	background-color: var(--btn1Background) !important;
	color: #fff !important;
	outline: none;
	border: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: bold;
	line-height: 1.5;
	font-size: 16px;
	text-align: center;
	text-decoration: none;
	vertical-align: middle;
	cursor: pointer;
	padding: 0.75rem 1rem;
	border-radius: 0.9rem;
	font-family: var(--fontFamily) !important;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn:hover,
.btn1:hover {
	filter: contrast(90%);
}

.btn:active,
.btn1:active {
	transform: translateY(0px) scale(0.98);
	filter: brightness(0.9);
}

button:disabled {
	opacity: 0.5;
	cursor: none;
}

.btn_outlined {
	box-sizing: border-box;
	width: 138px;
	height: 42px;	
	padding: 8px 14px;
	border: 1px solid var(--btn1Background) !important;
	color: var(--btn1Background) !important;
	background-color: transparent;
	cursor: pointer !important;
	text-decoration: none !important;
	vertical-align: middle;
	text-align: center !important;
	outline: none !important;
	font-weight: 500;
	font-size: 15px;
	display: flex;
	align-items: center;
	border-radius: 8px;
}

.btnDiv{
	display: flex;
	justify-content: flex-start;
	align-items: center;
}
.form_textfield, .Addform_textfield {
	font-family: "Poppins", sans-serif !important;	
} 

.form_textfield>.MuiInputBase-root {	
	font-size: 14px !important;
}

.form_textfield>.MuiInputBase-root > .MuiInputBase-input {
	font-size: 14px !important;
}

.form_textfield>.MuiInput-underline:after {
	border-bottom: 2px solid #000 !important;
}

.form_textfield>.MuiFormLabel-root.Mui-focused {
	color: #000 !important;
}

.Addform_textfield >.MuiInputBase-root {	
	font-size: 14px !important;
	height: 40px !important;
}

.allergice_textfield >.MuiInputBase-root {	
	font-size: 14px !important;
	height: 70px !important;
}

.Addform_textfield>.MuiFormLabel-root.Mui-focused{
	color: #000 !important;
}

.Addform_textfield > .MuiInputLabel-outlined.MuiInputLabel-shrink {
	transform: translate(14px, -7px) scale(0.75) !important;
}

.Addform_textfield >.MuiInputBase-root  > .MuiInputBase-input {
	font-size: 14px !important;
}

.Addform_textfield > .MuiFormLabel-root{
	transform: translate(14px,12px) scale(1) !important;
}
.allergice_textfield > .MuiFormLabel-root{
	transform: translate(14px,25px) scale(1) !important;
}

.Addform_DatePicker > .MuiOutlinedInput-root{
	height: 40px !important;	
	font-size: 14px !important;
}
.Addform_DatePicker > .MuiInputLabel-outlined{
	transform: translate(14px, 12px) scale(1);
}

.Addform_DatePicker > .MuiFormLabel-root.Mui-focused{
	color: #000 !important;
}

.Addform_CheckBox{
	margin-left: 0px !important;
}
.Addform_CheckBox > .MuiCheckbox-root.Mui-checked {
	color: #F07167 !important;

}
.Addform_CheckBox > .MuiFormControlLabel-label{
	padding-left: 8px;
}

.Addform_phonenumber > .react-tel-input .form-control{
height: 40px !important;
}

.Addform_dropdown > .MuiOutlinedInput-root { 	
	font-size: 14px !important;
	height:40px !important;
	
}
.Addform_dropdown  > .MuiInputLabel-outlined {
	transform: translate(14px, 12px) scale(1);
}

.Addform_dropdown > .MuiFormLabel-root.Mui-focused{
	color: #000 !important;
}

.Addform_menuitems > .MuiList-root{
	display: flex !important;
}
.MuiFormLabel-root.Mui-error{
	color:#000 !important;
}

.MuiFormHelperText-root.Mui-error { 
    display: none;
}

.headingLine{
	position: relative;
}

.deleteIconBox {
	width: 100px;
	height: 33px;
	cursor: pointer;	
	padding: 4px 10px !important;
	font-size: 13px;
	font-weight: 500;
	line-height: 22px;
	border: 1px solid #F07167;
	border-radius: 8px;
	color: #f07167;		
}

.deleteIconBox:active {
	transform: translateY(0px) scale(0.98);
	filter: brightness(0.9);
}

.IconColor {
	width: 24px;
	height: 24px;
	color: #f07167;
	cursor: pointer;
}
.form-dropdown>.MuiInput-underline:after {
	border-bottom: 2px solid #000 !important;
}

.form_textfield>.MuiOutlinedInput-root>.MuiInputBase-input {
	padding: 10px 14px !important;
}

.form-dropdown>.MuiInput-root {	
	font-size: 14px !important;
}
.form-dropdown>.MuiInput-root  > .MuiInputBase-input {
	font-size: 14px !important;
}
.form-dropdown>.MuiInputBase-root  > .MuiSelect-root {
	font-size: 14px !important;
}

.form-dropdown>.MuiFormLabel-root.Mui-focused {
	color: #000 !important;
}

/* .form_search_field>.MuiInputBase-root>.MuiInputAdornment-root>.MuiButtonBase-root>.MuiIconButton-label>.MuiSvgIcon-root {
	color: #00afb9 !important;
}

.date_search_field>.MuiInputBase-root>.MuiInputAdornment-root>.MuiButtonBase-root>.MuiIconButton-label>.MuiSvgIcon-root {
	color: #00afb9 !important;
}

.Addform_DatePicker>.MuiInputBase-root>.MuiInputAdornment-root>.MuiButtonBase-root>.MuiIconButton-label>.MuiSvgIcon-root {
	color: #00afb9 !important;
} */

.EthnicityLabel.MuiFormLabel-root.Mui-focused {
	color: rgba(0, 0, 0, 0.54) !important;
}

.errorText {
	color: red !important;
	font-size: 12px;
}

.adjs {
	font-size: 12px;
}

.loaderAdj {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 70vh;
}

.MuiCircularProgress-root {
	color: #4bd3c5 !important;
}

.listItems.MuiListItem-gutters {
	padding-left: 0px !important;
	padding-right: 0px !important;
}

.listItems.MuiListItem-root {
	padding-top: 0px !important;
	padding-bottom: 0px !important;
}

.gridHide{
	display: none;
}

.gridAlign2{
	padding-top: 16px ;
}

/* .Ethnicity-radio .MuiFormLabel-colorPrimary {
	color: #00000099 !important;
}

.radio-items {
	color: #00000099 !important;
} */

.radio-text>.MuiButtonBase-root {
	padding: 9px !important;
}
.feedback {
	position: fixed;
	right: 0;
	bottom: 37%;
	height: 160px;
	width: 32px;
	color: #FFF;
	cursor: pointer;
 }

 .feedback:active {
	transform: translateY(0px) scale(0.98);
	filter: brightness(1);
}

@media only screen and (max-width:1367px) {
	.form-dropdown>.MuiInput-root,
	.form_textfield>.MuiInputBase-root {
		font-size: 14px !important;
	}
}

/* ====Status change Modal css */

.react-confirm-alert-body > h1 {
	font-size: 20px !important;
}
.react-confirm-alert-overlay{
	background: rgba(255, 255, 255, 0.6) !important;
}
.react-confirm-alert-body{
	box-shadow: 0 6px 17px rgb(0 0 0 / 25%) !important;
}

.react-confirm-alert-button-group > button:nth-child(1){

	background-color: var(--btnBackground) !important;
	font-weight: bold;
	color:#fff;
}
.react-confirm-alert-button-group > button:nth-child(2){

	background-color: var(--btn1Background) !important;
	font-weight: bold;
	color:#fff;
}

.Status_name{
	color: var(--btnBackground) !important;
}


@media only screen and (min-width: 900px) {	
	.gridHide{
		display: block !important;
	}
	.gridAlign2 {
		padding-top: 0px !important;
	}

}

.loader1 {
	display:inline-block;
	font-size:0px;
	padding:0px;
 }
 .loader1 span {
	vertical-align:middle;
	border-radius:100%;
	
	display:inline-block;
	width:10px;
	height:10px;
	margin:3px 2px;
	-webkit-animation:loader1 0.8s linear infinite alternate;
	animation:loader1 0.8s linear infinite alternate;
 }
 .loader1 span:nth-child(1) {
	-webkit-animation-delay:-1s;
	animation-delay:-1s;
   background:rgba(245, 103, 115,0.6);
 }
 .loader1 span:nth-child(2) {
	-webkit-animation-delay:-0.8s;
	animation-delay:-0.8s;
   background:#00afb9;
 }
 .loader1 span:nth-child(3) {
	-webkit-animation-delay:-0.26666s;
	animation-delay:-0.26666s;
	background:#00afb9;
 }
 .loader1 span:nth-child(4) {
	-webkit-animation-delay:-0.8s;
	animation-delay:-0.8s;
	background:#00afb9;
   
 }
 .loader1 span:nth-child(5) {
	-webkit-animation-delay:-1s;
	animation-delay:-1s;
	background:rgba(245, 103, 115,0.6);
 }
 
 @keyframes loader1 {
	from {transform: scale(0, 0);}
	to {transform: scale(1, 1);}
 }
 @-webkit-keyframes loader1 {
	from {-webkit-transform: scale(0, 0);}
	to {-webkit-transform: scale(1, 1);}
 }