.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 10vh;
}

.headerlogo {
  width: 180px;
}

.headerlogo1 {
  width: 90px;
}

.headertext {
  width: 193px;
}

@media only screen and (max-width: 600px) {
  .headerlogo {
    width: 150px;
  }
  
  .headerlogo1 {
    width: 75px;
  }

}
